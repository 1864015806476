import axios from "axios";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CHAT_API_URL } from "../utils/api";

export default function useChatMessages(userId, chatId) {
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const skipLoading = sessionStorage.getItem("skipLoading");
    sessionStorage.removeItem("skipLoading");

    if (!!!chatId) {
      setIsLoading(false);
      return;
    }

    const cacheKey = `chat_messages_${userId}_${chatId}`;
    const cachedMessages = sessionStorage.getItem(cacheKey);

    if (
      cachedMessages &&
      cachedMessages !== "undefined" &&
      cachedMessages !== null &&
      !skipLoading
    ) {
      setChatMessages(JSON.parse(cachedMessages));
      setIsLoading(false);
      return;
    }

    if (skipLoading && skipLoading !== "undefined" && skipLoading !== null) {
      setIsLoading(false);
      return;
    }

    (async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${CHAT_API_URL}/get_messages?user_id=${userId}&conversation_id=${chatId}`
        );

        if (response.status !== 200) throw response;

        const messages = response.data;
        sessionStorage.setItem(cacheKey, JSON.stringify(messages));
        setChatMessages(messages);
      } catch (error) {
        if (error?.response?.status === 404) {
          toast.dismiss();
          toast.error("The chat you're trying access was not found.");
          setIsLoading(false);
          navigate("/chat");
          return;
        }
        toast.dismiss();
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while retrieving your message history. Please try again later."
            );
      } finally {
        setIsLoading(false);
      }
    })();
  }, [userId, chatId, navigate]);

  const updateChatMessages = (newMessages) => {
    const cacheKey = `chat_messages_${userId}_${chatId}`;
    sessionStorage.setItem(cacheKey, JSON.stringify(newMessages));
    setChatMessages(newMessages);
  };

  return {
    isLoading,
    chatMessages,
    setChatMessages: updateChatMessages,
  };
}
