import { format, isToday, isYesterday, parseISO, subDays } from "date-fns";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalContext";
import { getImageUrl } from "../../utils/helpers";

const parseChatDate = (dateString) => {
  const fixedDateString = dateString.replace(/-(\d{2}:\d{2}:\d{2})$/, "T$1");

  const utcDate = parseISO(fixedDateString);
  const localDate = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
  );

  return localDate;
};

export default function Sidebar({ chatNames, isLoading, chatId }) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;
  const navigate = useNavigate();

  const groupChatsByDate = (chats) => {
    const groups = {
      Today: [],
      Yesterday: [],
      "Past 7 Days": [],
      "Previous 30 Days": [],
      Months: {},
      Years: {},
    };

    const today = new Date();
    const sevenDaysAgo = subDays(today, 7);
    const thirtyDaysAgo = subDays(today, 30);

    chats.forEach((chat) => {
      const chatDate = parseChatDate(chat.date_created);

      if (isToday(chatDate)) {
        groups.Today.push(chat);
      } else if (isYesterday(chatDate)) {
        groups.Yesterday.push(chat);
      } else if (chatDate >= sevenDaysAgo) {
        groups["Past 7 Days"].push(chat);
      } else if (chatDate >= thirtyDaysAgo) {
        groups["Previous 30 Days"].push(chat);
      } else {
        const year = format(chatDate, "yyyy");
        const month = format(chatDate, "MMMM");

        if (!groups.Months[year]) groups.Months[year] = {};
        if (!groups.Months[year][month]) groups.Months[year][month] = [];
        groups.Months[year][month].push(chat);
      }
    });

    return groups;
  };

  const groupedChats = groupChatsByDate(chatNames);

  return (
    <aside className="md:w-1/5 md:h-[53dvh] md:overflow-auto w-full p-4 text-white bg-tertiary-light dark:bg-tertiary-dark md:rounded-l-xl">
      <div
        className={`relative ${
          (isLoading || chatNames?.length === 0) &&
          "flex flex-col items-center justify-center h-full"
        }`}>
        {isLoading ? (
          <>
            <img
              src={getImageUrl("loader.svg", isDark)}
              alt="loader"
              className="w-16 h-16"
            />
            <p>Loading chats...</p>
          </>
        ) : chatNames.length === 0 ? (
          <p className="text-primary-light dark:text-primary-dark text-lg font-semibold text-center">
            Start by typing something to create your first chat!
          </p>
        ) : (
          <div className="flex flex-col h-full">
            <button
              disabled={!!!chatId}
              className=" bg-primary-btn-light dark:bg-primary-btn-hover enabled:hover:bg-primary-btn-hover enabled:dark:hover:bg-primary-btn-hoverDark disabled:bg-opacity-70 disabled:cursor-not-allowed p-3 font-semibold text-center text-white transition duration-300 rounded-lg"
              onClick={() => navigate("/chat")}>
              New Chat
            </button>

            <>
              {Object.entries(groupedChats).map(([group, chats]) =>
                Array.isArray(chats)
                  ? chats.length > 0 && (
                      <div key={group}>
                        <h3 className="text-secondary-light dark:text-secondary-dark mt-4 mb-2 text-sm font-bold">
                          {group}
                        </h3>
                        {chats.map((chat) => (
                          <ChatItem
                            key={chat.id}
                            chat={chat}
                            chatId={chatId}
                            navigate={navigate}
                          />
                        ))}
                      </div>
                    )
                  : Object.entries(chats).map(([year, months]) => (
                      <div key={year}>
                        <h3 className="text-secondary-light dark:text-secondary-dark mt-4 mb-2 text-sm font-extrabold">
                          {year}
                        </h3>
                        {Object.entries(months).map(([month, monthChats]) => (
                          <div key={month}>
                            <h4 className="text-primary-light dark:text-primary-dark text-xs font-bold">
                              {month}
                            </h4>
                            {monthChats.map((chat) => (
                              <ChatItem
                                key={chat.id}
                                chat={chat}
                                chatId={chatId}
                                navigate={navigate}
                              />
                            ))}
                          </div>
                        ))}
                      </div>
                    ))
              )}
            </>
          </div>
        )}
      </div>
    </aside>
  );
}

function ChatItem({ chat, chatId, navigate }) {
  const isSelected = chat.id === chatId;

  return (
    <button
      key={chat.id}
      className={`pb-2 mb-2 px-2 py-2 rounded cursor-pointer w-full text-left overflow-clip ${
        isSelected
          ? "bg-primary-btn-light dark:bg-primary-btn-hover !bg-opacity-70 text-primary-dark cursor-default"
          : "text-primary-light dark:text-primary-dark hover:bg-secondary-light dark:hover:bg-secondary-dark transition-all duration-200"
      }`}
      onClick={() => !isSelected && navigate(`/chat/${chat.id}`)}>
      <p className="overflow-clip">
        <span className={`text-nowrap text-md`}>{chat.name}</span>
      </p>
    </button>
  );
}
