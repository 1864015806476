import ChatLoader from "./ChatLoader";
import Messages from "./Messages";
import QueryInput from "./QueryInput";
import React, { useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import useChatMessages from "../../hooks/useChatMessages";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CHAT_API_URL } from "../../utils/api";
import { getUser } from "../../utils/user";

export default function Chat({ isLoadingChatNames, chatId, setChatNames }) {
  const userInformation = getUser();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const {
    isLoading: isLoadingChatMessages,
    setChatMessages,
    chatMessages,
  } = useChatMessages(userInformation?.user?.id, chatId);
  const [isLoading, setIsLoading] = useState(false);
  const [isWebSearch, setIsWebSearch] = useState(false);

  useEffect(() => {
    setQuery("");
  }, [chatId]);

  const handleSubmit = useCallback(
    async function handleSubmit(e) {
      e.preventDefault();

      if (query.trim() === "") {
        return;
      }
      setIsLoading(true);
      const url = `${CHAT_API_URL}/${
        isWebSearch ? "web_" : ""
      }chat?query=${query.trim()}&user_id=${userInformation?.user?.id}${
        !!!chatId ? "" : `&conversation_id=${chatId}`
      }`;
      try {
        const response = await axios.get(url);
        if (response.status !== 200) throw response;
        if (!!!chatId) {
          setChatNames((prev) => {
            const newChat = {
              id: response.data.id,
              name: response.data.name,
              date_created: response.data.date_created,
            };
            return [newChat, ...prev];
          });
          sessionStorage.setItem("skipLoading", true);
          navigate(`/chat/${response.data.id}`);
        }
        const newMessages = [
          {
            role: "user",
            parts: [
              {
                text: query.trim(),
              },
            ],
          },
          {
            role: "assistant",
            parts: [
              {
                text: response.data.response,
              },
            ],
          },
        ];
        if (!!!chatId) {
          setChatMessages(newMessages);
        } else {
          setChatMessages((prev) => [...prev, ...newMessages]);
        }
        setQuery("");
      } catch (error) {
        toast.dismiss();
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error("An error occurred while sending your message.");
      }
      setIsLoading(false);
    },
    [
      chatId,
      setChatMessages,
      setChatNames,
      query,
      navigate,
      userInformation,
      isWebSearch,
    ]
  );

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        e.key === "Enter" &&
        (e.metaKey || e.ctrlKey) &&
        query.trim() !== "" &&
        !isLoading
      ) {
        e.preventDefault();
        handleSubmit(e);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [query, handleSubmit, isLoading]);

  if (isLoadingChatMessages || isLoadingChatNames) {
    return <ChatLoader />;
  }

  if (!!!chatId) {
    return (
      <div className="md:w-4/5 md:h-[53dvh] w-full text-white bg-secondary-light dark:bg-secondary-dark md:rounded-r-xl flex flex-col items-center justify-center p-6">
        <div className="w-3/4 space-y-4">
          <h3 className="text-primary-light dark:text-primary-dark text-4xl font-semibold text-center">
            What can I help you with?
          </h3>
          <QueryInput
            query={query}
            setQuery={setQuery}
            handleSubmit={handleSubmit}
            placeholder="Ask anything..."
            minHeight="100px"
            maxHeight="250px"
            isInitialView={true}
            isLoading={isLoading}
            isWebSearch={isWebSearch}
            setIsWebSearch={setIsWebSearch}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="md:w-4/5 md:h-[53dvh] w-full text-white bg-secondary-light dark:bg-secondary-dark md:rounded-r-xl flex flex-col p-6">
      <div className="flex-1 overflow-auto">
        <Messages chatMessages={chatMessages} isLoading={isLoading} />
      </div>

      <QueryInput
        query={query}
        setQuery={setQuery}
        handleSubmit={handleSubmit}
        placeholder="Ask anything..."
        minHeight="50px"
        maxHeight="150px"
        isInitialView={false}
        isLoading={isLoading}
        isWebSearch={isWebSearch}
        setIsWebSearch={setIsWebSearch}
      />
    </div>
  );
}
