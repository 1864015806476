import React, { useCallback, useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { getImageUrl } from "../../utils/helpers";

const QueryInput = ({
  query,
  setQuery,
  handleSubmit,
  placeholder = "Ask anything...",
  minHeight = "50px",
  maxHeight = "150px",
  isInitialView = false,
  isLoading,
  setIsWebSearch,
  isWebSearch,
}) => {
  const textareaRef = useRef(null);
  const previousLoadingState = useRef(isLoading);
  const isDisabled = !query.trim() || isLoading;
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = minHeight;

    const scrollHeight = textarea.scrollHeight;
    const newHeight = Math.min(
      scrollHeight,
      parseInt(maxHeight.replace("px", ""))
    );
    textarea.style.height = `${newHeight}px`;
  }, [minHeight, maxHeight]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      (e.metaKey || e.ctrlKey) &&
      e.target.value.trim() !== "" &&
      !isDisabled
    ) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleWebSearchToggle = () => {
    setIsWebSearch(!isWebSearch);
  };

  useEffect(() => {
    if (previousLoadingState.current === true && isLoading === false) {
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      }, 50);
    }
    previousLoadingState.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [query, adjustTextareaHeight]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [adjustTextareaHeight]);

  return (
    <form
      onSubmit={handleSubmit}
      className={isInitialView ? "w-full space-y-4" : "w-full"}>
      <div className="focus:outline-none bg-tertiary-light dark:bg-tertiary-dark text-secondary-light dark:text-secondary-dark flex flex-col w-full p-3 overflow-auto rounded-lg appearance-none">
        <textarea
          ref={textareaRef}
          disabled={isLoading}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={query}
          className="focus:outline-none bg-tertiary-light dark:bg-tertiary-dark text-secondary-light dark:text-secondary-dark disabled:opacity-80 w-full pr-4 overflow-auto rounded-lg appearance-none"
          placeholder={placeholder}
          style={{
            resize: "none",
            whiteSpace: "pre-wrap",
            minHeight: minHeight,
            maxHeight: maxHeight,
            height: minHeight,
          }}
        />
        <div className="flex items-center justify-between space-x-2">
          <button
            type="button"
            onClick={handleWebSearchToggle}
            className={`w-fit h-9 p-1 text-black dark:text-white rounded-lg flex items-center space-x-1 transition-all duration-200 pr-2 ${
              isWebSearch
                ? "bg-primary-btn-light dark:bg-primary-btn-dark text-white hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark"
                : "hover:bg-opacity-90 bg-secondary-light dark:bg-secondary-dark hover:bg-primary-light dark:hover:bg-primary-dark"
            }`}>
            <img
              src={getImageUrl("web.svg", isWebSearch ? true : isDark)}
              alt="Web Search"
              className="w-5 h-5"
            />
            <span className="text-sm">Search</span>
          </button>
          <button
            type="submit"
            disabled={isDisabled}
            className={`w-fit bg-primary-btn-light dark:bg-primary-btn-dark p-1 text-white rounded-lg ${
              isDisabled
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-opacity-90 hover:bg-primary-btn-dark dark:hover:bg-primary-btn-hoverDark"
            }`}
            style={{ width: "36px", height: "36px" }}>
            {isLoading ? (
              <img
                src={getImageUrl("loader.svg", true)}
                alt="loader"
                className="max-w-fit max-h-fit"
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#fff"
                className="w-5 h-5 mx-auto">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 5l7 7-7 7M5 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default QueryInput;
