import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { getImageUrl } from "../../utils/helpers";

export default function ChatLoader() {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;
  return (
    <div className="md:w-4/5 md:h-[53dvh] md:overflow-auto w-full p-4 text-white bg-secondary-light dark:bg-secondary-dark md:rounded-r-xl">
      <div className={"flex flex-col items-center justify-center h-full"}>
        <img
          src={getImageUrl("loader.svg", isDark)}
          alt="loader"
          className="w-16 h-16"
        />
        <p className="text-primary-light dark:text-primary-dark">
          Loading your chat messages...
        </p>
      </div>
    </div>
  );
}
