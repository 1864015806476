import ChatForm from "../components/chat/Chat";
import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import Sidebar from "../components/chat/Sidebar";
import useChatNames from "../hooks/useChatNames";
import { useParams } from "react-router-dom";
import { getUser } from "../utils/user";

export default function Chat({ userId }) {
  const userInformation = getUser();
  const { chatNames, isLoading, setChatNames } = useChatNames(
    userInformation?.user?.id
  );
  const { id: chatId } = useParams();
  return (
    <MainLayout>
      <ContentLayout horizontal>
        <Sidebar chatNames={chatNames} isLoading={isLoading} chatId={chatId} />
        <ChatForm
          userId={userId}
          isLoadingChatNames={isLoading}
          chatId={chatId}
          setChatNames={setChatNames}
        />
      </ContentLayout>
    </MainLayout>
  );
}
