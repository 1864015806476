import MenuButtonsContainer from "./MenuButtonsContainer";
import PropTypes from "prop-types";
import SettingsLogOutButton from "./SettingsLogOutButton";
import SettingsThemeButton from "./SettingsThemeButton";
import Spacer from "../ui/Spacer";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { navLinkClasses } from "../../utils/navbar/helpers";

export default function MobileNavBarMenu({
  isDark,
  isOpenMenu,
  setIsOpenMenu,
  clearUser,
}) {
  const [logoutDisplayOptions, setLogoutDisplayOptions] = useState(false);
  const [themeDisplayOptions, setThemeDisplayOptions] = useState(false);

  return (
    isOpenMenu && (
      <div className="md:hidden dark:bg-secondary-dark rounded-b-2xl bg-secondary-light z-50 flex flex-col px-6 py-4 space-y-3 text-center shadow-md">
        <NavLink
          onClick={() => setIsOpenMenu(false)}
          className={({ isActive }) => navLinkClasses(isActive) + " mx-auto"}
          to="/">
          Home
        </NavLink>
        <NavLink
          onClick={() => setIsOpenMenu(false)}
          className={({ isActive }) => navLinkClasses(isActive) + " mx-auto"}
          to="/projects">
          Projects
        </NavLink>
        <NavLink
          onClick={() => setIsOpenMenu(false)}
          className={({ isActive }) =>
            `${navLinkClasses(isActive)} ${isActive && "!mb-2"} mx-auto`
          }
          to="/tools">
          Tools
        </NavLink>
        <NavLink
          onClick={() => setIsOpenMenu(false)}
          className={({ isActive }) =>
            `${navLinkClasses(isActive)} ${isActive && "!mb-2"} mx-auto`
          }
          to="/chat">
          Chat
        </NavLink>
        <Spacer />
        <MenuButtonsContainer openMenu isMobile>
          <SettingsThemeButton
            isDark={isDark}
            displayOptions={themeDisplayOptions}
            setThemeDisplayOptions={setThemeDisplayOptions}
            setLogoutDisplayOptions={setLogoutDisplayOptions}
          />
          <SettingsLogOutButton
            clearUser={clearUser}
            displayOptions={logoutDisplayOptions}
            setThemeDisplayOptions={setThemeDisplayOptions}
            setLogoutDisplayOptions={setLogoutDisplayOptions}
          />
        </MenuButtonsContainer>
      </div>
    )
  );
}

MobileNavBarMenu.propTypes = {
  isDark: PropTypes.bool.isRequired,
  isOpenMenu: PropTypes.bool.isRequired,
  setIsOpenMenu: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
};
