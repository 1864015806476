import CustomLink from "../components/ui/CustomLink";
import ErrorMessage from "../components/ui/ErrorMessage";
import Footer from "../components/login/Footer";
import FormHeader from "../components/ui/FormHeader";
import InputContainer from "../components/ui/InputContainer";
import InputField from "../components/ui/InputField";
import Overview from "../components/login/Overview";
import PasswordErrorContainer from "../components/login/PasswordErrorContainer";
import PropTypes from "prop-types";
import { useReducer } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { initialState, loginReducer } from "../reducers/LoginReducer";
import { API_URL, initAxiosInterceptors } from "../utils/api";
import { setUser } from "../utils/user";

export default function Login({ setUserInformation }) {
  const [state, dispatch] = useReducer(loginReducer, initialState);
  const { email, emailError, password, passwordError, loading } = state;
  const navigate = useNavigate();

  function validateData() {
    let isValid = true;
    dispatch({ type: "RESET_ERRORS" });
    if (password.trim() === "") {
      dispatch({
        type: "SET_PASSWORD_ERROR",
        payload: "Password must not be empty",
      });
      isValid = false;
    }
    if (email.trim() === "") {
      dispatch({ type: "SET_EMAIL_ERROR", payload: "Email must not be empty" });
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      dispatch({ type: "SET_EMAIL_ERROR", payload: "Invalid email format" });
      isValid = false;
    }
    return isValid;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();
    if (validateData()) {
      dispatch({ type: "SET_LOADING", payload: true });
      toast.loading("Logging in...");
      try {
        const response = await fetch(`${API_URL}/users/login`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
          timeout: 30000,
        });

        if (response.status !== 200) throw response;

        const data = await response.json();

        const userInformation = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          user: data.user,
        };

        setUser(userInformation);
        setUserInformation(userInformation);
        initAxiosInterceptors();
        toast.dismiss();
        navigate("/");
      } catch (error) {
        try {
          const data = await error.json();
          toast.dismiss();
          data?.detail
            ? toast.error(`${data.detail}`)
            : toast.error("An error occurred while logging in");
        } catch (error) {
          toast.dismiss();
          toast.error("An error occurred while logging in");
        }
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    }
  }

  return (
    <main className="bg-primary-light dark:bg-primary-dark flex items-center justify-center min-h-screen">
      <section className="flex justify-center w-full px-6">
        <article className="bg-secondary-light dark:bg-secondary-dark md:w-1/2 xl:w-1/3 rounded-2xl md:rounded-r-none w-full px-6 py-8 space-y-8 shadow-2xl">
          <FormHeader content={"Please login to your account"} />
          <form onSubmit={handleSubmit} className="space-y-8">
            <InputContainer>
              <InputField
                autocomplete="email"
                value={email}
                error={emailError}
                setValue={(value) =>
                  dispatch({ type: "SET_EMAIL", payload: value })
                }
                resetError={() =>
                  dispatch({ type: "SET_EMAIL_ERROR", payload: "" })
                }
                id="email"
                type="email"
              />
              <ErrorMessage error={emailError} />
            </InputContainer>
            <InputContainer>
              <InputField
                autocomplete="current-password"
                value={password}
                error={passwordError}
                setValue={(value) =>
                  dispatch({ type: "SET_PASSWORD", payload: value })
                }
                resetError={() =>
                  dispatch({ type: "SET_PASSWORD_ERROR", payload: "" })
                }
                id="password"
                type="password"
              />
              <PasswordErrorContainer>
                <ErrorMessage error={passwordError} />
                <CustomLink to="/forgot_password">
                  Forgot your password?
                </CustomLink>
              </PasswordErrorContainer>
            </InputContainer>

            <Footer loading={loading} />
          </form>
        </article>
        <Overview />
      </section>
    </main>
  );
}

Login.propTypes = {
  setUserInformation: PropTypes.func.isRequired,
};
