import { useEffect, useRef, useState } from "react";

export default function Messages({ chatMessages, isLoading }) {
  const messagesEndRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages, showLoader]);

  useEffect(() => {
    if (isLoading) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [isLoading]);

  return (
    <div className="flex flex-col w-full space-y-6 overflow-auto">
      {chatMessages?.map((msg, index) => (
        <div
          key={index}
          className={`rounded-lg ${
            msg.role === "user"
              ? "p-3 bg-tertiary-light dark:bg-tertiary-dark text-secondary-light dark:text-secondary-dark self-end max-w-lg"
              : "dark:text-primary-dark text-primary-light self-start"
          }`}>
          {msg.parts.map((part, i) => (
            <p key={i} className="whitespace-pre-wrap">
              {part.text}
            </p>
          ))}
        </div>
      ))}

      {showLoader && (
        <div className="dark:text-primary-dark text-primary-light self-start">
          <div className="flex items-center space-x-2">
            <div className="typing-indicator">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <style jsx>{`
            .typing-indicator {
              display: flex;
              align-items: center;
            }
            .typing-indicator span {
              height: 8px;
              width: 8px;
              margin: 0 1px;
              background-color: currentColor;
              border-radius: 50%;
              opacity: 0.6;
              display: inline-block;
              animation: typing 1.5s infinite ease-in-out;
            }
            .typing-indicator span:nth-child(1) {
              animation-delay: 0s;
            }
            .typing-indicator span:nth-child(2) {
              animation-delay: 0.2s;
            }
            .typing-indicator span:nth-child(3) {
              animation-delay: 0.4s;
            }
            @keyframes typing {
              0% {
                transform: translateY(0px);
                opacity: 0.6;
              }
              50% {
                transform: translateY(-5px);
                opacity: 1;
              }
              100% {
                transform: translateY(0px);
                opacity: 0.6;
              }
            }
          `}</style>
        </div>
      )}

      <div ref={messagesEndRef} />
    </div>
  );
}
