import axios from "axios";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { CHAT_API_URL } from "../utils/api";

export default function useChatNames(userId) {
  const [chatNames, setChatNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadChatNames = async () => {
      const cacheKey = `chat_names_${userId}`;
      const cachedData = sessionStorage.getItem(cacheKey);
      if (cachedData && cachedData !== "undefined" && cachedData !== "null") {
        try {
          const parsedData = JSON.parse(cachedData);
          setChatNames(parsedData);
          return;
        } catch (e) {
          console.warn("Error parsing cached chat names", e);
        }
      }

      try {
        setIsLoading(true);
        const response = await axios.get(
          `${CHAT_API_URL}/chats_names?user_id=${userId}`
        );
        if (response.status !== 200) throw response;

        sessionStorage.setItem(cacheKey, JSON.stringify(response.data));
        setChatNames(response.data);
      } catch (error) {
        toast.dismiss();
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while retrieving your chats. Please try again later."
            );
      } finally {
        setIsLoading(false);
      }
    };

    loadChatNames();
  }, [userId]);

  const updateChatNames = (newChatNames) => {
    const cacheKey = `chat_names_${userId}`;
    sessionStorage.setItem(cacheKey, JSON.stringify(newChatNames));
    setChatNames(newChatNames);
  };

  return {
    chatNames,
    isLoading,
    setChatNames: updateChatNames,
  };
}
